import { graphql, useStaticQuery } from "gatsby";
import { PrismicSliceManipulation } from "../utils/PrismicSliceManipulation";

export const useIndexData = () => {
  const indexDataRaw = useStaticQuery(rawData).allPrismicPage.nodes[0];

  const indexData = PrismicSliceManipulation(indexDataRaw.data.body);

  const indexHero = indexData[0]; // hero image
  const metaDescription = indexDataRaw.data.metadescription.text;
  const metaTitle = indexDataRaw.data.metatitle.text;
  const ogImageUrl = indexDataRaw.data.ogmetaimage.url;

  return { indexHero, metaDescription, metaTitle, ogImageUrl };
};

const rawData = graphql`
  {
    allPrismicPage(
      filter: { data: { title: { text: { eq: "Startseite" } } } }
    ) {
      nodes {
        id
        data {
          metadescription {
            text
          }
          metatitle {
            text
          }
          title {
            text
          }
          ogmetaimage {
            url
          }
          body {
            ... on PrismicPageDataBodyCallToAction {
              id
              slice_type
              primary {
                title {
                  text
                }
                description {
                  text
                  html
                }
                image {
                  gatsbyImageData
                  alt
                }
                button_label
              }
            }
            ... on PrismicPageDataBodyTextbox {
              id
              slice_type
              primary {
                description {
                  text
                }
                textboxtitle {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`;
