import React from "react";
import Hero from "../components/hero/Hero";
import { useIndexData } from "../query/indexQuery";
import Seo from "../components/seo/Seo";
// import Map from "../components/map/Map";
// import { mapLocation } from "../constants/location";

const IndexPage = () => {
  const { indexHero, metaDescription, metaTitle, ogImageUrl } = useIndexData();

  return (
    <>
      <Seo
        title={metaTitle}
        description={metaDescription}
        ogImageUrl={ogImageUrl}
        ogTitle={metaTitle}
        ogDescription={metaDescription}
      />
      <div className="page homepage">
        <Hero
          heroData={indexHero}
          withContentBox={true}
          className="whiteFont-imageTransparent"
        />
      </div>
      {/* <Map location={mapLocation} /> */}
    </>
  );
};

export default IndexPage;
